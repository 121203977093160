<template lang="pug">
.home
  h1 iss.ms - issm's *

  ul(style="list-style: none;")
    li
      a(href="http://blog.iss.ms/") Blog (in Japanese)
    li
      a(href="http://twitter.com/issm") Twitter
    li
      a(href="http://github.com/issm") GitHub
    li
      a(href="https://www.mixcloud.com/issm/") Mixcloud
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
